@media (min-width: 1300px) {
	.container {
	  max-width: 1280px;

	}
	.card2_css{
		height: 24px !important;
	}
  }
  
  /*@media (min-width: 992px) {
	  .navbar-toggler {
		  display: none;
	  }
	  .btn.close-btn {
		  display: none;
	  }
	  
  }*/
  .logo-images {
    width: 165px !important;
    height: auto !important;
  }
  @media (min-width: 991px) {
	
	.trers-grid-image {
	  img {
		max-width: 150px;
	  }
	}
	.cardbutton-12{
		width:27% !important;
	}
	.viewcard-que{
		width:27% !important;
	}
  }
  @media (min-width: 1280px) {
	.mayanli-but{
		height: 27px;
	}
}
@media (max-width: 1280px) {
	.mayanli-but{
		height: 29px;
	}
}
  @media (max-width: 1199px) {
	footer{
        .footer-links {
            display: block;
        }
    }
	.free-shipping-section {
	  ul {
		flex-flow: wrap;
		li {
		  margin-bottom: 15px;
		}
	  }
	}
	body {
	  padding-top: 47px;
	}
  
	.header {
	  .flex-container {
		.header-row-one {
		  display: block;

		  .account-section {
			position: absolute;
			top: 0;
			width: 100%;
			border-bottom: 1px solid #00000012;
			left: 0;
			z-index: 99;
			background: #fff;
			.account {
			  justify-content: flex-end;
    		 margin-top: 10px;
			}
		  }
		}
	  }
	}

  }
  @media (max-width: 991px) {

	.product-details-wrapper {
		.slider-nav {
			bottom:6px;
			
		}
		.details-wrapper {
			
			.product-all-details {
				.row {
					.col-left {
						flex: auto;
					}
				}
				.product-addtional-details{
					padding-bottom:15px;
					&:before {
						width:100%;
					}
				}
			}
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 30px;
		  }
		}
	  }
	/**Product**/
	.product-box-inner {
	  .product-image {
		max-width: 150px;
	  }
	  .product-detail {
		.price-wrapper {
		  position: inherit;
		  transform: none;
		}
		.add-to {
		  position: inherit;
		}
	  }
	  .verified {
		top: 10px;
	  }
	}
  
	.best-products {
	  .row-fluid {
		display: block;
		.tyers-grid {
		  justify-content: space-between;
		  li {
			display: inline-block;
			margin: 15px 0px 15px 0px;
			text-align: center;
		  }
		}
	  }
	  .column {
		&.single-image {
		  margin-top: 15px;
		}
	  }
	}
  }
  
  @media (max-width: 768px) {
	.mob-filter{
		
		z-index: 200;
		background-color: $bg-color;
		width: 100%;
		top: 0;
    	bottom:0;
		left:0;
    	position:fixed;
    	overflow-y:scroll;
    	overflow-x:hidden;
	}

	.filter-button{
		display: block !important;
		position: fixed;
    	bottom: 4%;
    	left: 45%;
   		width: 50%;
    	height: 8%;
		left: 130px!important;
		border-radius: 50px;
		border: 2px solid $primary;
		font-size: 18px;
		font-weight: 600;
		-webkit-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		-moz-box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
		box-shadow: 0px 0px 33px -10px rgba(0,0,0,0.59);
	}


	.details-wrapper {
		padding-right: 0px !important;
    	padding-left: 0px !important;
    	margin-right: 0px !important;
    	margin-left: 0px !important;
		.slider-nav {
			bottom:6px;
			max-width: 240px;
			min-width: 230px;
		}
	}
	.banner-content {
		.content {
		  h2, h1 {
			font-size: 20px;
		  }
		}
	  }
	.aside-group {
	  display: block;
	}
  
	/**Title**/
	.content-title {
	  .title-box {
		.title {
		  &:after {
			display: none;
		  }
		}
	  }
	}
  
	.header {
	  .flex-container {
		display: block;
		.logo {
		  text-align: center;
		  padding: 15px;
		  margin:0px auto;
		}
		.header-row-one {
			
			.search-bar-group {
				
				.search-bar {
					margin-top:10px;
					margin-right: -4rem;
					width:100% !important;
					
				}
			}
		}
	  }
	}
  
	.content-1-section {
	  position: static;
	  border-radius: 0px;
	}
	.homepage-banner-slider {
	  .owl-theme {
		.owl-dots {
		  bottom: 0;
		}
	  }
	}
  
	.best-products {
	  .column {
		.tyers-grid {
		  li {
			display: block;
			text-align: center;
		  }
		}
	  }
	}
  }
  .mobile-filter{
	display: none;
  }
  .desktop-filter{
	display: block;
  }
  @media (max-width: 768px) {
	
	.checkout-btn{
		min-width:fit-content; 
		padding-left:0px !important; 
		height:30px !important;
		position: relative;
		top: 0px;
		width: 150px !important;
		margin-top: 16%;
	}

	.collection-wrapper {
	  .col-grid-box {
		padding: 0px 10px 0px 10px;
	  }
	}
	
	footer{
        .footer-links {
            display: grid !important;
        }
		.footer-col{
			justify-content: center !important;
			padding: 5px;
			margin:5px;
			min-width:136%;
		}
    }

	.banner-content {
	  .content {
		h2, h1 {
		  font-size: 18px;
		}
	  }
	}
	.header {
	  .flex-container {
		.header-row-one {
		  .search-bar {
			margin-left: 0px;
		  }
		  .account-section{
			position: fixed !important;
			z-index: 2 !important;
			padding-right:0px;
			.account{
				margin-right: 10px !important;
			}
			.dropdown-toggle{
				font-size:10px !important;
			}
			.fa-user{
				font-size:10px !important
			}
		  }
		}
	  }
	}


	.product-box-inner {
	  display: block;
	  padding: 10px 0px 10px 0;
	  .img-wrapper {
		padding-bottom: 10px;
		.product-image {
		  max-width: 100%;
		  width: 100%;
		}
	  }
  
	  .product-detail {
		padding-left: 0px;
	  }
	  .verified {
		top: 10px;
		transform: none;
	  }
	}
  
	/*Pagenation**/
	.product-pagination {
	  .row {
		display: block;
		> div {
		  margin-bottom: 10px;
		  text-align: center;
		  p {
			margin-bottom: 0px;
		  }
		  .pagination {
			justify-content: center;
		  }
		}
	  }
	}
	.mobile-filter{
		display: block !important;
	  }
	.desktop-filter{
		display : none !important;
	}
	.mob-categories{
		
		opacity: 1;
		padding:10px;
		font-family: 'proxima-regular','sans-serif'!important;
		position: fixed;
		background-color: white;
		z-index: 100;
		width: 100%;
		height: 100%;
		border-right: 50px solid rgba(0, 0, 0, 0.5);
		overflow-y: auto;
		top:0;
		left:0;
		background-clip: padding-box;
		
		
	}
	.touch-out{
		 background-color:white;
		 position: fixed;
		 z-index: 999;
		 width: 60px;
		 right: 0px;
		 height:300%;
		 top:0px;
		 overflow:hidden;
		
	}
	// body{
	// 	position: fixed !important;
	// }

  }
  /*** Range **/
  
  @media (max-width: 991px) and (min-width: 640px) {
	.product-name {
	  font-size: 18px;
	}
	.price {
	  font-size: 18px;
	}
  
	.product-price {
	  .tax-includes {
		font-size: 13px;
	  }
	}
	.verified {
	  font-size: 13px;
	}
	.add-to {
	  .btn-solid {
		font-size: 15px;
	  }
	}
  }
  .mayanproduct-list-image{
	width:100%!important;
	min-height: 100%!important;
	max-height: 100%!important;
	
	border-radius: 10px!important;
	object-fit: contain;
}
  @media screen and (max-width: 600px){
	.modal.show{
		transform: translate(0, 0)!important;
	}
	.mob_pass_w{
		width: 200%!important;
	}
	.aligncheckout_button{
		width: 109%!important;

	}
	.ckt_button{
		margin-left: 7px!important;

	}
	.mob_lst_image{
		margin-top: 10px;;
	}
	.mayan_ftre_name{
		margin-left: 0px
	}
	.check_insure{
	left: -294px;
    bottom: -29px;
    white-space: nowrap;
	}
	.ckt_del_mode{
		position:relative;
		left:0px!important;
		white-space: nowrap;
	}
	.homecard_f{
		display:flex ;
	}
	.trash-position{
		left: 0%!important;
	}
	.align_pro_ckt{
		left: -10px!important;
		top: 0px!important;

	}
.rating-box{
	margin-left: 27% !important;
    margin-right: 27% !important;
}
.viewCartlistmay{
	width: 73px!important;
		margin: 13px 0 0px!important;
		display: flex !important;
		justify-content: space-evenly;
		font-size: small;
		font-weight: 700;
		padding-left: 4px;
		padding-right: 4px;
		background-color: black !important;
}
	.viewCart{
		width: 75px!important;
		margin: 13px 0 0px!important;
		display: flex !important;
		justify-content: space-evenly;
		font-size: small;
		font-weight: 700;
		padding-left: 4px;
		padding-right: 4px;
		background-color: black !important;
	}
	.viewCart-mayan{
		width: 75px!important;
		margin: 13px 0 0px!important;
		display: flex !important;
		justify-content: space-evenly;
		font-size: small;
		font-weight: 700;
		padding-left: 4px;
		padding-right: 4px;
		background-color: black !important;
	}
	.viewcard-que{
		width:45% !important;
	}
.m_cart_bt{
	width: 70px !important;
}
.mayan_mar{
	margin: auto !important;
}
.cartButton{
	width: 45% !important;
}
.mar-top-may{
	margin-top: 10px;
}
.mar_top_may{
	margin-top: 2px;
}
.mayancartButton{
	// margin: 29px 0px 18px -115px;
    width: 80px!important;
    position: relative;
    // margin-left: -116px;
    margin-top:13px;
	border: 3px solid #3c707c !important;
	float: left !important;


}
.add-cart1{
	position: relative;
    bottom: -25px !important;
}
.mayan-product-list-cards {
    width: 95% !important;
    margin: auto !important;
}

	.mayanbt_continue{
		width: 50%!important;
	}
	.mob_view{
		left: -75px;
		margin-top: 3px!important;

	}
	.home_fe_card{
		font-size: 13px!important;
		left: 1px!important;
		// position:relative;lcolor: #3c707c;top: 55px!important;font-weight: 600

	}
.card2_css1{
	
    height: 23px;

}

	.m_cart_bt{
		font-size: small;
                          font-weight: bold;
                          width: 70px;position: relative;top: 30px!important;
                          border: 3px solid #3c707c !important;
                          color: #000000 !important;
						  left: -68px;
	}
	
	.mayanproduct-list-image{
		width:100%!important;
		min-height: 100%!important;
		max-height: 100%!important;
		
		border-radius: 10px!important;
		object-fit: contain;
	}
	// .home_cart{
	// 	position: relative;
	// 	top: 15px!important;
	// 	left: -185%;
		
	// }

	.home_feature{
		padding-right:0px!important;
		margin-top: 0px!important;
		//left: -134%;
	}	
	.m_top{
		position: relative;
        top:-27px;
		left: -10px;
	}
	.mob-logo {
		transform: scale(.9);
		top: 10px!important;
		left: 30%;
		position: fixed;
		z-index: 3;
	}
	.mayanviewCart{
		background-color: #000!important;
       color: #fff!important;
       border: none!important;
	   position: relative;
	   width: 80px !important;
	   top: 25px!important;
    

	}
	
// .quantity-box{
// 	margin: 0px -8px 39px 4px;

// }
	}
	@media (max-width: 991px){
		.mob-responsive{
			position: relative;
			top: -40px;
		}
		.justify-content-center{
			flex-direction: column!important;
           align-content: center!important;

		}
		.d-flex {
			display: flex!important;
			align-content: space-between;
			flex-wrap: wrap !important;
		}
		.owl-nav{
			position: relative;
            top: -182px;
}
         .wtdo{
			position: relative;
            top: -191px


         }
		 .responsive{
			position: relative;
            top: -200px !important;
		}
		.reviewcard{
			height: 400px !important;
		}

		 }
		 @media (min-width: 640px){
			.home_feature{
				margin-top: 5px;
				padding-right:14px;
				bottom: -4px!important;
				width: 80px;
				display: flex;
				justify-content: space-evenly;
			}
			.logo-section{
				margin-left: 50px !important;
			}
		 }
		 @media screen and (max-width: 914px) {
			
			.responsivemob{
				max-height: 321px !important;
			}
			.thumb{
				//background-color: #ebf5f7;
				width: 100%!important;
				margin-left: -2px!important
				
				
				
			}
			    owl-theme .owl-nav {
				margin-top: 20px!important;
				text-align: center;
				-webkit-tap-highlight-color: transparent;
			}

		 }
		 .owl-theme .owl-nav {
			margin-top: -20px;
			text-align: center;
			-webkit-tap-highlight-color: transparent;
		}
		@media screen and (max-width: 768px) {
			.cost-deera{
				position: relative;
				top: 50px !important;
			}
			.carousel-control-prev{
				display: none!important;

			}
			.carousel-control-next{
				display: none!important;

			}
			.review-align{
				width: 100%!important;
			}
			.top-image-cover{
				padding-left: 35px!important;

			}
			.mayantopcategory-img{
				
                    position: relative;
                    border-radius: 50%;
                    margin: 0 auto 5px;
                    overflow: hidden;
                    border: 1px ridge #3c707c;
                    width: 120px!important	;
                    height: 120px!important;
                    padding: 10px;
                    cursor: pointer;
                    transition: width .5s,height .8s;
                    transition-timing-function: ease;
					object-fit: cover;
			}
			
			.mayan_productName{
				position: relative;
			bottom: -20px !important;

			}
			.owl-prev{
				position: relative;
				left: -120px;
				top: -110px;
				color: black!important;
				background-color:transparent!important;
				border:transparent!important;
			}
			.owl-next{
				position: relative;
				right: -120px;
				top: -110px;
				color: black!important;
				background-color:transparent!important;
				border: white!important;
			}
			.owl-theme .owl-nav [class*=owl-] {
				position: relative;
				border: transparent;
			}
			
			.justify-content-between{
				display: block!important;

			}
			.ht{
				max-height: 160px !important;
                min-height: 160px !important;
                  }
			 }
           
			 .list-value{
				color: #3c707c!important;
				font-weight: 500!important;
			 }
		
			 .mayan_cart{
				position: relative;
				bottom: -35px;
			 }
			 .mayan_cart1{
				position: relative;
				bottom: -31px;
			 }



			 .description{
				position: relative;
				top: -18px;
			 }
	.cktbtn{
		width:30%
	}
	.owl-nav{
		//display: block!important;
	}
		
	.mayan_feature{
		margin-top: -10px;
	}
	
	.home_feature{
		margin-top: 5px;
		padding-right:14px;
		bottom: -4px!important;
		
	}	
	.home_cart{
		position: relative;
		top: 10px!important;
	}
	.m_cart_bt{
		font-size: small;
                          font-weight: bold;
                          width: 70px;position: relative;top: 5px;
                          
                          color: #000000 !important;
	}
	.home_fe_card{
		position:relative;left: -11px;color: #3c707c;font-weight: 600;
		font-size: 14px;margin-top: 10px

	}
	.mob_view{
		margin-left: 11px;

	}
	.mayanbt_continue{
		width: 30%;
	}
	.carousel-item:empty {
		display: none!important;
		margin-right: 100%!important;
	  }
	  .homecard_f{
		display:flex ;
	}
	.ckt_del_mode{
		position:relative;left: 100px;
	}
	.mayan_ftre_name{
		margin-left: -10px
	}
	@media (min-width: 576px){
		.modal-dialog {
			max-width: 100%!important;
			margin: 1.75rem auto;
		}

	}
	.modal.show{
		transform: translate(-2px, 60%);
	}
	.card2_css{
		height: 24px !important;
	}

	
	.mayanli-but{
	    float: right;
		width: 70px;
	}
	
// 	@media (min-width:601px){
		
// .viewCart{
// 	background-color: #101111!important;
//     color: #fff!important;
//     border: none!important;
// 	position: absolute;
// 	width: 75px;
//     height: 29px;
//     margin: 1px 0 -30px 179px;
//     font-size: small;
//     font-weight: 700;
//     padding: 2px 0 0 3px;
// 	background-color: black !important;
// }
// }
	
@media only screen and (max-width:1300px) and (min-width: 1200px)  {

	.viewCartlistmay{
		background-color: #101111!important;
		color: #fff!important;
		border: none!important;
		width: 73px!important;
		margin: 1px 0 -30px 159px;
		font-size: small;
		font-weight: 700;
		padding: 2px 0 0 3px;
		display: flex !important;
		justify-content: space-evenly;
	}
.viewCart{
	background-color: #101111!important;
    color: #fff!important;
    border: none!important;
	width: 75px!important;
    margin: 1px 0 -30px 159px;
    font-size: small;
    font-weight: 700;
    padding: 2px 0 0 3px;
	display: flex !important;
	justify-content: space-evenly;
}
.viewCart-mayan{
	background-color: #101111!important;
    color: #fff!important;
    border: none!important;
    margin: 1px 0 -30px 159px;
    font-size: small;
    font-weight: 700;
    padding: 2px 0 0 3px;
	display: flex !important;
	justify-content: space-evenly;
}

}


	
@media only screen and (max-width:1450px) and (min-width: 1301px)  {

	.viewCartlistmay{
		background-color: #101111!important;
		color: #fff!important;
		border: none!important;
		width: 73px!important;
		margin: 1px 0 -30px 180px;
		font-size: small;
		font-weight: 700;
		padding: 2px 0 0 3px;
		display: flex !important;
		justify-content: space-evenly;
	}
	.viewCart{
		background-color: #101111!important;
		color: #fff!important;
		border: none!important;
		width: 75px!important;
		margin: 1px 0 -30px 180px;
		font-size: small;
		font-weight: 700;
		padding: 2px 0 0 3px;
		display: flex !important;
		justify-content: space-evenly;
	}
	.viewCart-mayan{
		background-color: #101111!important;
		color: #fff!important;
		border: none!important;
		margin: 1px 0 -30px 180px;
		font-size: small;
		font-weight: 700;
		padding: 2px 0 0 3px;
		display: flex !important;
		justify-content: space-evenly;
	}
	
	}

	@media only screen and (max-width:1600px) and (min-width: 1451px)  {
		.viewCartlistmay{
			background-color: #101111!important;
			color: #fff!important;
			border: none!important;
			width: 73px!important;
			margin: 1px 0 -30px 205px;
			font-size: small;
			font-weight: 700;
			padding: 2px 0 0 3px;
			display: flex !important;
			justify-content: space-evenly;
		}
		.viewCart{
			background-color: #101111!important;
			color: #fff!important;
			border: none!important;
			width: 75px!important;
			margin: 1px 0 -30px 220px;
			font-size: small;
			font-weight: 700;
			padding: 2px 0 0 3px;
			display: flex !important;
			justify-content: space-evenly;
		}

		.viewCart-mayan{
			background-color: #101111!important;
			color: #fff!important;
			border: none!important;
			margin: 1px 0 -30px 220px;
			font-size: small;
			font-weight: 700;
			padding: 2px 0 0 3px;
			display: flex !important;
			justify-content: space-evenly;
		}
	}


	@media only screen and (max-width:1750px) and (min-width: 1601px)  {
		.viewCart{
			background-color: #101111!important;
			color: #fff!important;
			border: none!important;
			width: 75px!important;
			margin: 1px 0 -30px 248px;
			font-size: small;
			font-weight: 700;
			padding: 2px 0 0 3px;
			display: flex !important;
			justify-content: space-evenly;
		}
		.viewCart-mayan{
			background-color: #101111!important;
			color: #fff!important;
			border: none!important;
			margin: 1px 0 -30px 248px;
			font-size: small;
			font-weight: 700;
			padding: 2px 0 0 3px;
			display: flex !important;
			justify-content: space-evenly;
		}
	}

	@media only screen and (max-width:1850px) and (min-width: 1751px)  {

		.viewCart{
			background-color: #101111!important;
			color: #fff!important;
			border: none!important;
			width: 75px!important;
			margin: 1px 0 -30px 281px;
			font-size: small;
			font-weight: 700;
			padding: 2px 0 0 3px;
			display: flex !important;
			justify-content: space-evenly;
		}
		.viewCart-mayan{
			background-color: #101111!important;
			color: #fff!important;
			border: none!important;
			margin: 1px 0 -30px 281px;
			font-size: small;
			font-weight: 700;
			padding: 2px 0 0 3px;
			display: flex !important;
			justify-content: space-evenly;
		}
	}

	@media only screen and (max-width:1920px) and (min-width: 1851px)  {
		.viewCart-mayan{
			background-color: #101111!important;
			color: #fff!important;
			border: none!important;
			margin: 1px 0 -30px 306px;
			font-size: small;
			font-weight: 700;
			padding: 2px 0 0 3px;
			display: flex !important;
			justify-content: space-evenly;
		}
		.viewCart{
			background-color: #101111!important;
			color: #fff!important;
			border: none!important;
			width: 75px!important;
			margin: 1px 0 -30px 306px;
			font-size: small;
			font-weight: 700;
			padding: 2px 0 0 3px;
			display: flex !important;
			justify-content: space-evenly;
		}

	}