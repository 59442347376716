// BOOTSTRAP
$primary: #3c707c;
$secondary: #fdfdfd;
$success: #68d105;
$warning: #ebaa05;
$danger: #ef1d3c;
$light: #f5f5f5;

// #9ab973 #c4c3d0 #C2C1BD
// OTHERS
// Back-Ground color
$bg-color: #ffffff;

// Other Pallet Colors
$pallet2: #000000;

// Main Menu related
$menu-underline-color: #53bcd1;
$menu-color: #09404b;

// button-colors
$secondary-btn-color: #f2f2f2;
$secondary-button-text: #333;

// carousel
$carousel-heading: #09404b;
$carousel-bg-color: #d7e7eb;

// star rating
$unchecked-star:#f7e09c;
$checked-star:#fdb915;

// client-slogan
$slogan-color:#000;

// menu heading
$mh-bg-color:#000000bf;
$mh-color:#fff;
$md-border:#cdcdcd;
$menu-txt-color:#3b8c9c;

// product listing
$product-listing-heading-color:#777;

// others
$white:#ffffff;
$black:#000;
$light-ash:#777;
$red:red;

// buttons
$button-radius:0px;

// product-card
$product-cd:"tams";

// signup in footer
$signup-ft-bg: #dbdbdb;

// footer backgroun
$footer-bg:#3c707c;

// info card
$info-card:#729ba5;
$info-bg:rgb(243, 243, 243);

// forms
$form-header:#000000;
$form-label-color: #333333;

// checkout
$checkout-tab-color:#6bb3c5;

// fonts
$theme-font:'proxima-regular','sans-serif';

// ABOUT US
$aboutus_bg:#ebf5f7;
$aboutus_heading: #3b8c9c;